document.addEventListener("turbolinks:load", function() {
    function restoreAccordionPanel(storageKey, accordionId) {
        var activeItem = localStorage.getItem(storageKey);
        if (activeItem) {
            //remove default collapse settings
            $(accordionId + " #collapseOne").removeClass('show');

            //show the account_last visible group
            $("#" + activeItem).addClass("show");
        }
    }

    function clearAccordionPanel(storageKey, accordionId) {
        var activeItem = localStorage.getItem(storageKey);
        if (activeItem) {
            $("#" + activeItem).removeClass("show");
        }
    }

    function saveActiveAccordionPanel(storageKey, e) {
        localStorage.setItem(storageKey, e.target.id);
        localStorage.setItem('accordion-order-default', 'false');
    }

    $(function () {
        $('#accordion.order').on('shown.bs.collapse', function (e) {
            saveActiveAccordionPanel('accordion-activePanel', e);
        })
    });

    if(localStorage.getItem('accordion-order-default') !== 'true') {
        restoreAccordionPanel('accordion-activePanel', '#accordion');
    }

    let orderSubmit = document.querySelector('#order-submit-container');
    if(orderSubmit){
        orderSubmit.addEventListener('click', () => {
            clearAccordionPanel('accordion-activePanel', '#accordion');
            localStorage.setItem('accordion-order-default', 'true');
        })
    }
});