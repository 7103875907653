document.addEventListener("turbolinks:load", function(){
    function debounce(func, timeout = 500) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    function clickBtBuy(el) {
        el.siblings('.bt_buy').click();
    }

    function toggleButtonClass(el) {
        const $button = el.siblings('.bt_buy');
        const $input = el.find('.quantity');
        $button.addClass('btn_added');
        console.log(el.addClass('btn_added'));
        console.log($button);
        console.log($input);
        // if (parseInt($input.val()) > 0) {
        //     $button.addClass('btn_added');
        // } else {
        //     $button.removeClass('btn_added');
        // }
    }

    const processChanges = debounce((el) => {
        clickBtBuy(el);
        toggleButtonClass(el);
    });

    $(document).off('keypress', '.quantity_inner .quantity').on('keypress', '.quantity_inner .quantity', function(e) {
        if(e.keyCode == 13) {
            e.preventDefault();
        }
    })

    // Прибавляем кол-во по клику

    $(document).off('click', '.quantity_inner .bt_plus').on('click', '.quantity_inner .bt_plus', function (e) {
        e.preventDefault();
        let $input = $(this).parent().find('.quantity').get(0);
        let step = Math.ceil($input.value / $input.step) * $input.step - $input.value;
        if (step == 0) step = $input.step || 1;
        let count = parseInt($input.value) + parseInt(step);
        count = count > parseInt($input.max) ? parseInt($input.max) : count;
        $input.value = count;
        $this = $(this);
        processChanges($this);
    });

    // Убавляем кол-во по клику
    $(document).off('click', '.quantity_inner .bt_minus').on('click', '.quantity_inner .bt_minus', function (e) {
        e.preventDefault();
        let $input = $(this).parent().find('.quantity').get(0);
        let step = $input.value - (Math.floor($input.value / $input.step)) * $input.step;
        if (step == 0) step = $input.step || 1;
        let count = parseInt($input.value) - parseInt(step);
        count = count < 1 ? 0 : count;
        $input.value = count;
        $this = $(this);
        processChanges($this);
    });



    // Убираем все лишнее и невозможное при изменении поля
    $(document).off('change input', '.quantity_inner .quantity').on('change input', '.quantity_inner .quantity', function() {
        if (this.value.match(/[^0-9]/g)) {
            this.value = this.value.replace(/[^0-9]/g, '');
        };
        if (this.value == "") {
            this.value = '';
        };
        if (this.value > parseInt($(this).max)) {
            this.value = parseInt($(this).max);
        };

        $this = $(this).parent();
        processChanges($this);
    });

    $(document).off("click", '.quantity_inner .quantity').on("click", '.quantity_inner .quantity', function () {
        if (this.value == 0 || this.value == '0') {
            this.value = "";
        };
    });

    // Убираем все лишнее и невозможное при изменении поля
    $(document).off("change keyup input", '.quantity_inner .quantity.min_count').on("change keyup input", '.quantity_inner .quantity.min_count', function () {
        if (this.value == "") {
            this.value = '0';
        };
        if (this.value < parseInt($(this).min)) {
            this.value = parseInt($(this).min);
        };
    });

    $(document).off('change', '.quantity_inner .quantity').on('change', '.quantity_inner .quantity', function () {
        $this = $(this).parent();
        processChanges($this);
    });
});
