document.addEventListener("turbolinks:load", function() {
    var selects = document.querySelectorAll(".tri-state-select");

    selects.forEach(function(selectContainer) {
        var selectField = selectContainer.querySelector(".tri-state-select-input");
        var label = selectContainer.querySelector("label");
        var parentEl = label.parentNode;
        var values = {
            empty: selectContainer.dataset.valueEmpty,
            false: selectContainer.dataset.valueFalse,
            true: selectContainer.dataset.valueTrue
        };

        // Измененный массив состояний для порядка true -> false -> empty
        var states = ["true", "false", "empty"];
        var valueToState = {
            [values.empty]: "empty",
            [values.false]: "false",
            [values.true]: "true"
        };

        var stateToValue = {
            "empty": values.empty,
            "false": values.false,
            "true": values.true
        };

        // Определение текущего состояния и его индекса в массиве
        var currentState = valueToState[selectContainer.dataset.state] || "empty"; // Default to "empty" if not matched
        console.log(currentState);
        var stateIndex = states.indexOf(currentState);

        function updateDisplay() {
            var currentState = states[stateIndex];
            var currentValue = stateToValue[currentState];
            selectField.value = currentValue;
            selectContainer.dataset.state = currentValue;

            // Обновление классов для стилизации
            selectContainer.classList.remove("true", "false", "empty");
            selectContainer.classList.add(currentState);
        }

        function handleClick(event) {
            event.preventDefault(); // Предотвращение стандартного поведения label
            stateIndex = (stateIndex + 1) % states.length;
            updateDisplay();
        }

        parentEl.addEventListener("click", handleClick);
        updateDisplay();
    });
});