document.addEventListener("turbolinks:load", function() {
    (function() {
        var headerFixed = document.querySelector('#header-fixed');
        var contact_info = document.getElementById('contact_info');
        var main_logo = document.getElementById('main_logo');

        function updateMarginTop() {
            // console.log('updateMarginTop');
            var headerHeight = headerFixed.offsetHeight;
            $('#main-content').css("margin-top", headerHeight);
        }

        function debounce(func, wait) {
            var timeout;
            return function() {
                clearTimeout(timeout);
                timeout = setTimeout(func, wait);
            };
        }

        function handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            // Добавление/удаление тени в зависимости от положения прокрутки
            if (scrollTop > 50) {
                headerFixed.classList.add('shadow-bottom');
            } else {
                headerFixed.classList.remove('shadow-bottom');
            }

            // Обработка изменения размеров и видимости элементов при прокрутке
            if (contact_info && main_logo) {
                if (scrollTop > 10) {
                    // Прокрутка вниз
                    contact_info.classList.add('scroll_hidden');
                    main_logo.classList.add('main_logo_img_mini');
                } else {
                    // Прокрутка вверх
                    contact_info.classList.remove('scroll_hidden');
                    main_logo.classList.remove('main_logo_img_mini');
                }
            }

            addTransitionEndListener(headerFixed);

            // Обновление margin-top при прокрутке
            updateMarginTop();
        }

        // Обработчик событий прокрутки и изменения размера
        const debouncedUpdateMarginTop = debounce(updateMarginTop, 50);
        window.addEventListener('scroll', debounce(handleScroll, 50));
        window.addEventListener('resize', debouncedUpdateMarginTop);

        // Обработчик завершения перехода на элементе headerFixed и его внутренних элементах
        function addTransitionEndListener(element) {
            element.addEventListener('transitionend', function(event) {
                if (['height', 'padding', 'margin', 'width'].includes(event.propertyName)) {
                    // console.log('transitionend');
                    updateMarginTop();
                }
            });
        }

        // Добавляем обработчик на headerFixed
        addTransitionEndListener(headerFixed);

        // Добавляем обработчики на все внутренние элементы, которые могут повлиять на высоту headerFixed
        var internalElements = headerFixed.querySelectorAll('*');
        internalElements.forEach(function(element) {
            addTransitionEndListener(element);
        });

        // Первоначальное обновление margin-top
        updateMarginTop();
    })();

    $(function () {
        $(window).resize(function() {
            $('#main-content').css("padding-bottom", $("#resource_menu").height());
        }).resize();
    });
})